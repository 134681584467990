import { template as template_452b040d27004a8fa3200ad5c3341e96 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_452b040d27004a8fa3200ad5c3341e96(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
