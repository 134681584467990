import { template as template_3d0ec3a5cfc048a0a179df53e7401f66 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_3d0ec3a5cfc048a0a179df53e7401f66(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
