import { template as template_f0e5cb7dcff34cecb2f2ca5a877882d0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_f0e5cb7dcff34cecb2f2ca5a877882d0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
