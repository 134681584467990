import { template as template_76f9576585d64e8d871ecbb3c4a0acec } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_76f9576585d64e8d871ecbb3c4a0acec(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
