import { template as template_d3956836ffb643709dc700630c946b32 } from "@ember/template-compiler";
const WelcomeHeader = template_d3956836ffb643709dc700630c946b32(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
